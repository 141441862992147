import React, { JSXElementConstructor } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useSelector from '../utils/hooks/useSelector';

export interface NonAuthorizedRouteProps extends RouteProps {
  component: JSXElementConstructor<any>,
  // [key: string]: any
}

function NonAuthorizedRoute({ component: Component, ...rest }: NonAuthorizedRouteProps) {
  const { currentUser } = useSelector((state) => state.auth);
  const redirect = !!currentUser;

  return (
    <Route
      {...rest}
      render={(props) => (redirect
        ? <Redirect to="/" from={props.location.pathname} />
        : <Component {...props} />)}
    />
  );
}

export default NonAuthorizedRoute;
