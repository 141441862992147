export const SET_GLOBAL_COUNTERS = 'SET_GLOBAL_COUNTERS';

export interface GlobalCounters {
  activeDialogs: number,
}

export interface SetGlobalCounters {
  type: typeof SET_GLOBAL_COUNTERS,
  counters: GlobalCounters
}


export type CounterActions = SetGlobalCounters
