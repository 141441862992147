import {
  DeliveryService,
  RateItem,
  ReferenceActions,
  Seller,
  SET_DELIVERY_SERVICES,
  SET_RATE_LIST,
  SET_SELLER_LIST,
  SET_SURCHARGE_TYPES_LIST,
  SurchargeType,
} from './types';

export interface ReferenceState {
  deliveryServicesList: Array<DeliveryService>,
  deliveryServices: {[key: string]: DeliveryService},
  sellerList: Seller[],
  surchargeTypesList: SurchargeType[],
  ratesList: RateItem[],
  rates: {[key: string]: RateItem}
}

const initialState: ReferenceState = {
  deliveryServicesList: [],
  deliveryServices: {},
  sellerList: [],
  surchargeTypesList: [],
  ratesList: [],
  rates: {},
};

function prepareDeliveryServices(rawDeliveryServices: Array<DeliveryService>): {[key: string]: DeliveryService} {
  const deliveryServices: {[key: string]: DeliveryService} = {};
  if (rawDeliveryServices) {
    for (let i = 0; i < rawDeliveryServices.length; i++) {
      deliveryServices[rawDeliveryServices[i].key] = rawDeliveryServices[i];
    }
  }
  return deliveryServices;
}

const prepareRates = (rateList: RateItem[]) => {
  const rates: {[key: string]: RateItem} = {};

  rateList.forEach((rate) => {
    rates[rate.code] = rate;
  });
  return rates;
};

export default function (state = initialState, action: ReferenceActions): ReferenceState {
  switch (action.type) {
    case SET_DELIVERY_SERVICES:
      return { ...state, deliveryServicesList: action.deliveryServices, deliveryServices: prepareDeliveryServices(action.deliveryServices) };
    case SET_SELLER_LIST:
      return { ...state, sellerList: action.list };
    case SET_SURCHARGE_TYPES_LIST:
      return { ...state, surchargeTypesList: action.list };
    case SET_RATE_LIST:
      return { ...state, ratesList: action.rates, rates: prepareRates(action.rates) };
    default: return state;
  }
}
