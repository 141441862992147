import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';

const CollisionReport = React.lazy(() => import('../components/CollisionReportSummary'));

function CollisionReportView(props: any) {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress />}>
        <CollisionReport {...props} />
      </Suspense>
    </MainView>
  );
}

export default CollisionReportView;
