import React, { useCallback } from 'react';
import {
  Avatar, Badge, Card, Divider, Dropdown, Popover,
} from 'antd';
import useSelector from '../../utils/hooks/useSelector';
import useDispatch from '../../utils/hooks/useDispatch';
import { signOut, switchAccount } from '../../apps/auth/action-creators';

const UserProfileDropdown = () => {
  const dispatch = useDispatch();
  const { currentUser, userAccountList } = useSelector((state) => state.auth);
  const handleSignOut = useCallback(() => dispatch(signOut()), []);

  const handleSwitch = useCallback(
    (accountId) => {
      dispatch(switchAccount(accountId));
    },
    [dispatch],
  );


  return (
    <Card className="gx-card gx-mb-0" style={{ width: 300 }}>
      {userAccountList.length > 1 && (
        <div className="gx-mt-2">
          {userAccountList.map((account) => (
            account.accountId === currentUser?.accountId
              ? (
                <div>
                  <Badge status="warning" className="gx-m-0"/>
                  {account.title}
                  <Divider/>
                </div>
              )
              : (
                <div className="gx-pointer" onClick={() => handleSwitch(account.accountId)}>
                  {account.title}
                  <Divider/>
                </div>
              )
          ))}

        </div>
      )}

      <div className="gx-text-center">
        <span onClick={() => handleSignOut()} className="gx-pointer">
          Выход
        </span>
      </div>
    </Card>
  );
};

function UserProfile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser);
  const handleSignOut = useCallback(() => dispatch(signOut()), []);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => handleSignOut()}>Выход</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Dropdown trigger={['click']} placement="bottomRight" overlay={<UserProfileDropdown/>}>
        <div>
          <Avatar src="https://via.placeholder.com/150x150" className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          <span className="gx-avatar-name gx-d-none gx-d-md-inline-block">
            {user ? `${user.firstName} ${user.lastName}` : 'Loading'}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
          </span>
        </div>
      </Dropdown>
    </div>
  );
}

export default UserProfile;
