import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { Action, combineReducers } from 'redux';
import { DESTROY_SESSION } from './types/redux.d';
import auth from './apps/auth/reducer';
import waybill from './apps/waybill/reducer';
import chat from './apps/chat/reducer';
import settings from './apps/settings/reducer';
import dashboard from './apps/dashboard/reducer';
import statistic from './apps/statistic/reducer';
import reviews from './apps/reviews/reducer';
import claim from './apps/claim/reducer';
import reference from './apps/reference/reducer';
import counters from './apps/counter/reducer';
import notifications from './apps/notifications/reducer';

const history = createBrowserHistory();

const appReducer = combineReducers({
  auth,
  waybill,
  chat,
  settings,
  dashboard,
  statistic,
  reviews,
  claim,
  reference,
  counters,
  notifications,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof appReducer>

const rootReducer = (state: RootState | undefined, action: Action): RootState => {
  if (action.type === DESTROY_SESSION) {
    // @ts-ignore
    return appReducer({ reference: state?.reference }, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
export { history };
