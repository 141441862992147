import {
  SET_CENT,
  SET_CURRENT_USER,
  SET_USER_ACCOUNT_LIST,
  SetCent,
  SetCurrentUser,
  SetToken,
  SetUserAccountList,
  User,
  UserAccount,
} from './types';
import { ThunkAction } from '../../types/redux';
import subscribeWs from '../../utils/websocket';
import { startDialogChecker, stopDialogChecker } from '../chat/action-creators';
import { loadReferences } from '../reference/action-creators';
import { loadGlobalCounters } from '../counter/action-creators';

export const setCurrentUser = (user: User | null): SetCurrentUser => ({
  type: SET_CURRENT_USER,
  user,
});

export const setToken = (accessToken: string | null, refreshToken?: string, expiredAt?: number): SetToken => ({
  type: 'SET_TOKEN',
  accessToken,
  refreshToken,
  expiredAt,
});

export const setCent = (cent: any): SetCent => ({
  type: SET_CENT,
  cent,
});

export const setUserAccountList = (list: UserAccount[]): SetUserAccountList => ({
  type: SET_USER_ACCOUNT_LIST,
  list,
});

export const loadUserAccounts = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/user/account/list/').then(
  (response: { accounts: Array<UserAccount> }) => {
    dispatch(setUserAccountList(response.accounts));
    return Promise.resolve(response);
  },
);

export const getCurrentUserDetail = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/user/current/detail/').then(
  (response: User) => {
    dispatch(setCurrentUser(response));
    dispatch(loadUserAccounts());

    if (response.allowedService) {
      dispatch(subscribeWs());
      dispatch(startDialogChecker());
      dispatch(loadReferences());
      dispatch(loadGlobalCounters());
    }

    return Promise.resolve();
  },
);

export const signOut = (): ThunkAction<void> => (dispatch, getState) => {
  const { cent } = getState().auth;
  if (cent && typeof cent.disconnect === 'function') cent.disconnect();
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpired');
  dispatch(setCurrentUser(null));
  dispatch(setToken(null));
  dispatch(stopDialogChecker());
};

export const updateToken = (): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/token_update/').then(
  (response: any) => {
    const { accessToken, refreshToken } = response;
    const expiredAt = new Date().getTime() + 300000;
    dispatch(setToken(accessToken, refreshToken, expiredAt));
  },
  () => {
    dispatch(signOut());
  },
);

export const switchAccount = (accountId: number, redirectQuery?: string): ThunkAction => (dispatch, getState, http) => http.put(
  '/api/v1/user/account/switch/', { account_id: accountId },
).then(
  () => dispatch(updateToken()).then(
    () => {
      if (redirectQuery) window.location.replace(`/?${redirectQuery}`);
      else window.location.replace('/');
      return Promise.resolve();
    },
  ),
);

export const authorizeUser = (username: string, password: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/login/', {
  username,
  password,
})
  .then(
    (response: { accessToken: string, refreshToken: string }) => {
      const { accessToken, refreshToken } = response;
      const expiredAt = new Date().getTime() + 300000;
      dispatch(setToken(accessToken, refreshToken, expiredAt));
      return dispatch(getCurrentUserDetail());
    },
    (reject: anyObject) => Promise.reject(reject),
  );

export const loginAs = (accessToken: string, refreshToken: string): ThunkAction => (dispatch) => {
  const expiredAt = new Date().getTime() + 300000;
  dispatch(setToken(accessToken, refreshToken, expiredAt));
  return dispatch(getCurrentUserDetail());
};
