import React, { useEffect } from 'react';
import useDispatch from '../utils/hooks/useDispatch';
import useSelector from '../utils/hooks/useSelector';
import { getCurrentUserDetail } from '../apps/auth/action-creators';
import CircularProgress from '../components/CircularProgress';
import Router from '../router';
import { setScreenWidth } from '../apps/settings/action-creators';

function Base(props: anyObject) {
  const dispatch = useDispatch();
  const [userInited, setUserInited] = React.useState(false);

  const onWindowWidthUpdate = () => {
    dispatch(setScreenWidth(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowWidthUpdate);
    return () => {
      window.removeEventListener('resize', onWindowWidthUpdate);
    };
  }, []);

  useEffect(() => {
    dispatch(getCurrentUserDetail()).finally(() => setUserInited(true));
  }, [dispatch]);

  return (
    <>
      {
        userInited
          ? <Router />
          : <CircularProgress />
      }
    </>
  );
}

export default Base;
