import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import App from './App';
import configureStore from './store';
import httpService from './utils/httpService';
import { setToken } from './apps/auth/action-creators';

const store = configureStore({}, httpService);

const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');
let expiredAt = localStorage.getItem('tokenExpire');
if (expiredAt) expiredAt = parseInt(expiredAt, 10);

store.dispatch(setToken(accessToken, refreshToken, expiredAt, false));

const render = (Component) => {
  // Хак что бы все не рассыпалось если юзер использует перевод страницы
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }

  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component store={store} />
    </AppContainer>,
    document.getElementById('root'),
  );
};


// Render once
render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
