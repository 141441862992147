import { UploadFile } from 'antd/es/upload/interface';
import moment from 'moment';
import {
  ADD_DIALOGS,
  ADD_MESSAGE,
  ADD_MESSAGES,
  ADD_UPLOAD_FILE,
  ChatActions, ChatTab,
  Dialog,
  Message, REMOVE_DIALOG_BY_ID,
  REMOVE_UPLOAD_FILE,
  REPLACE_DIALOG,
  REPLACE_MESSAGE, RESET_CURRENT_DIALOG,
  RESET_UPLOAD_FILES, SET_CHAT_TAB,
  SET_CURRENT_DIALOG, SET_DIALOG_CHECKER_TIMER,
  SET_DIALOG_COUNT,
  SET_DIALOG_LIST,
  SET_MESSAGES_COUNT,
  SET_MESSAGES_LIST, SET_WAYBILL_FOR_DIALOG,
} from './types';
import removeByFieldValue from '../../utils/array/remove';
import sortArrayOfObject, { sort_by } from '../../utils/array/sort';
import replaceByFieldValue from '../../utils/array/replace';
import { Waybill } from '../waybill/types';

type chatState = {
  dialogList: Dialog[],
  dialogCount: number,
  currentDialog: Dialog | null,
  dialogMessages: Array<Message>,
  dialogMessagesCount: number,
  uploadFiles: Array<UploadFile>,
  currentTab: ChatTab,
  dialogCheckerTimer: any,
  waybillForDialog: Waybill | null,
};

const initialState: chatState = {
  dialogList: [],
  dialogCount: 0,
  currentDialog: null,
  dialogMessages: [],
  dialogMessagesCount: 0,
  uploadFiles: [],
  currentTab: ChatTab.ACTIVE,
  dialogCheckerTimer: null,
  waybillForDialog: null,
};

const replaceMessage = (messages: Array<Message>, message: Message, messageId: string) => {
  const index = messages.findIndex((_) => _.messageId === messageId);
  const newMessagesList = messages.slice();
  newMessagesList[index] = message;
  return newMessagesList;
};

const filterUniqueMessage = (arr: Array<Message>) => {
  const uniqueArr: Array<Message> = [];
  arr.forEach((item) => {
    if (!uniqueArr.find((_) => _.messageId === item.messageId)) uniqueArr.push(item);
  });
  return uniqueArr;
};

const filterUniqueDialogs = (arr: Dialog[]): Dialog[] => {
  const uniqueArr: Dialog[] = [];
  arr.forEach((item) => {
    if (!uniqueArr.find((_) => _.dialogId === item.dialogId)) uniqueArr.push(item);
  });
  return uniqueArr;
};

const processDialogList = (arr: Dialog[]): Dialog[] => arr.map((_) => ({
  ..._,
  lastMessageDate: typeof _.lastMessageDate === 'string' ? moment(_.lastMessageDate) : _.lastMessageDate,
}));

const sortDialogList = (arr: Dialog[]): Dialog[] => {
  const copy = [...arr];
  return copy.sort(sort_by('closed', { name: 'lastMessageDate', moment: true, reverse: true }));
};

export default function (state = initialState, action: ChatActions): chatState {
  switch (action.type) {
    case SET_DIALOG_LIST:
      return { ...state, dialogList: sortDialogList(processDialogList(action.list)) };
    case SET_DIALOG_COUNT:
      return { ...state, dialogCount: action.count };
    case SET_MESSAGES_LIST:
      return { ...state, dialogMessages: action.messages };
    case SET_MESSAGES_COUNT:
      return { ...state, dialogMessagesCount: action.count };
    case ADD_MESSAGE:
      return { ...state, dialogMessages: filterUniqueMessage(sortArrayOfObject([...state.dialogMessages, action.message], 'createdAt', { date: true })) };
    case ADD_MESSAGES:
      return { ...state, dialogMessages: filterUniqueMessage(sortArrayOfObject([...state.dialogMessages, ...action.messages], 'createdAt', { date: true })) };
    case ADD_UPLOAD_FILE:
      return { ...state, uploadFiles: [...state.uploadFiles, action.file] };
    case RESET_UPLOAD_FILES:
      return { ...state, uploadFiles: initialState.uploadFiles };
    case REMOVE_UPLOAD_FILE:
      return { ...state, uploadFiles: removeByFieldValue(state.uploadFiles, 'uid', action.file.uid) };
    case REPLACE_MESSAGE:
      return { ...state, dialogMessages: replaceMessage(state.dialogMessages, action.message, action.messageId) };
    case ADD_DIALOGS:
      return {
        ...state,
        dialogList: sortDialogList(filterUniqueDialogs(processDialogList([...state.dialogList, ...action.list]))),
      };
    case SET_CURRENT_DIALOG:
      return { ...state, currentDialog: action.dialog };
    case REPLACE_DIALOG:
      return { ...state, dialogList: sortDialogList(processDialogList(replaceByFieldValue(state.dialogList, 'dialogId', action.dialog))) };
    case RESET_CURRENT_DIALOG:
      return { ...state, currentDialog: initialState.currentDialog };
    case SET_CHAT_TAB:
      return { ...state, currentTab: action.tab };
    case REMOVE_DIALOG_BY_ID:
      return { ...state, dialogList: removeByFieldValue(state.dialogList, 'dialogId', action.dialogId) };
    case SET_DIALOG_CHECKER_TIMER:
      return { ...state, dialogCheckerTimer: action.timer };
    case SET_WAYBILL_FOR_DIALOG:
      return { ...state, waybillForDialog: action.waybill };
    default: return state;
  }
}
