import {DialogReviewAnswerStat, ReviewsActions, SET_DIALOG_REVIEW_STAT} from './types';

type ReviewsState = {
  dialogReviewStat: DialogReviewAnswerStat[],
};

const initialState: ReviewsState = {
  dialogReviewStat: [],
};

export default function (state = initialState, action: ReviewsActions) {
  switch (action.type) {
    case SET_DIALOG_REVIEW_STAT:
      return { ...state, dialogReviewStat: action.stat };
    default: return state;
  }
}
