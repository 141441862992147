export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_CENT = 'SET_CENT';
export const SET_USER_ACCOUNT_LIST = 'SET_USER_ACCOUNT_LIST';

export interface User {
  id: number,
  username: string,
  email: string,
  firstName: string,
  lastName: string,
  allowedService: string,
  accountId: number,
}

export type UserAccount = {
  accountId: number,
  title: string,
};

export interface Token {
  accessToken: string | null,
  refreshToken: string | null | undefined,
  expiredAt: number | undefined
}

export interface SetCurrentUser {
  type: typeof SET_CURRENT_USER,
  user: User | null,
}

export interface SetToken {
  type: typeof SET_TOKEN,
  accessToken: string | null,
  refreshToken?: string | null,
  expiredAt?: number,
}

export interface SetCent {
  type: typeof SET_CENT,
  cent: any
}

export interface SetUserAccountList {
  type: typeof SET_USER_ACCOUNT_LIST,
  list: UserAccount[],
}

export type authActions = SetCurrentUser | SetToken | SetCent | SetUserAccountList;
