import Cookie from 'js-cookie';
import {
  authActions,
  SET_CENT,
  SET_CURRENT_USER,
  SET_TOKEN,
  SET_USER_ACCOUNT_LIST,
  Token,
  User,
  UserAccount,
} from './types';

type authState = {
  currentUser: User | null,
  token: Token,
  cent: any,
  userAccountList: UserAccount[],
};

const initialState: authState = {
  currentUser: null,
  token: {
    accessToken: null,
    refreshToken: null,
    expiredAt: undefined,
  },
  cent: null,
  userAccountList: [],
};

export default function (state = initialState, action: authActions) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.user };
    case SET_TOKEN:
      if (action.refreshToken) {
        localStorage.setItem('refreshToken', action.refreshToken);
      } else {
        localStorage.removeItem('refreshToken');
      }
      if (action.accessToken) {
        Cookie.set('partnersToken', action.accessToken);
        localStorage.setItem('accessToken', action.accessToken);
      } else {
        localStorage.removeItem('accessToken');
      }
      localStorage.setItem('tokenExpire', String(action.expiredAt));

      return {
        ...state,
        token: {
          accessToken: action.accessToken,
          refreshToken: action.refreshToken,
          expiredAt: action.expiredAt,
        },
      };
    case SET_CENT:
      return { ...state, cent: action.cent };
    case SET_USER_ACCOUNT_LIST:
      return { ...state, userAccountList: action.list };
    default: return state;
  }
}
