export const SET_DIALOG_REVIEW_STAT = 'SET_DIALOG_REVIEW_STAT';

export type DialogReviewAnswerStat = {
  question: string;
  value: number;
};

export interface SetDialogReviewStat {
  type: typeof SET_DIALOG_REVIEW_STAT,
  stat: DialogReviewAnswerStat[]
}

export type ReviewsActions = SetDialogReviewStat;
