import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';

const DashboardTable = React.lazy(() => import('../components/DashboardTable'));
function SafetyOfCargoView(props: any) {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress />}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <DashboardTable {...props} />
      </Suspense>
    </MainView>
  );
}

export default SafetyOfCargoView;
