import {
  DeliveryService,
  RateItem,
  Seller,
  SET_DELIVERY_SERVICES,
  SET_RATE_LIST,
  SET_SELLER_LIST,
  SET_SURCHARGE_TYPES_LIST,
  SetDeliveryServices,
  SetRateList,
  SetSellerList,
  SetSurchargeTypesList,
  SurchargeType,
} from './types';
import { ThunkAction } from '../../types/redux';

export const setDeliveryServices = (deliveryServices: Array<DeliveryService>): SetDeliveryServices => ({
  type: SET_DELIVERY_SERVICES,
  deliveryServices,
});

export const setSellerList = (list: Seller[]): SetSellerList => ({
  type: SET_SELLER_LIST,
  list,
});

export const setSurchargeTypesList = (list: SurchargeType[]): SetSurchargeTypesList => ({
  type: SET_SURCHARGE_TYPES_LIST,
  list,
});

export const setRateList = (rates: RateItem[]): SetRateList => ({
  type: SET_RATE_LIST,
  rates,
});

export const loadDeliveryServices = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/rates/delivery_services/').then(
  (response: {deliveryServices: Array<DeliveryService>}) => {
    dispatch(setDeliveryServices(response.deliveryServices));
    return Promise.resolve(response);
  },
);

export const getRateList = (deliveryService?: string | null, query?: string | null): ThunkAction => (dispatch, getState, http) => {
  const params: any = {};

  if (deliveryService) params.delivery_service = deliveryService;
  if (query) params.query = query;

  return http.get('/api/v1/rates/reference/rate/list/', params);
};

export const loadSellerList = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/contract/seller/list/',
).then(
  (response: {list: Seller[]}) => {
    dispatch(setSellerList(response.list));
    return Promise.resolve(response);
  },
);

export const loadSurchargeTypesList = (): ThunkAction<void> => (dispatch, getState, http) => http.get(
  '/api/v1/tms/surcharge/list/',
).then(
  ({ items }: {items: SurchargeType[]}) => {
    dispatch(setSurchargeTypesList(items));
  },
);

export const loadRates = (): ThunkAction<void> => (dispatch, getState, http) => http.get(
  '/api/v1/rates/rate/list/',
).then(
  ({ rates }: { rates: RateItem[] }) => {
    dispatch(setRateList(rates));
  },
);

export const loadReferences = (): ThunkAction<void> => (dispatch) => {
  // dispatch(loadDeliveryServices());
  dispatch(loadSurchargeTypesList());
  dispatch(loadRates());
};
