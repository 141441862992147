import React from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';


const GlobalNotificationDetail = React.lazy(() => import('../components/GlobalNotificationDetail'));

function GlobalNotificationDetailView(props: any) {
  return (
    <MainView>
      <React.Suspense fallback={<CircularProgress/>}>
        <GlobalNotificationDetail {...props} />
      </React.Suspense>
    </MainView>
  );
}

export default GlobalNotificationDetailView;
