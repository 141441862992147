import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';

const ClaimDetail = React.lazy(() => import('../components/ClaimDetail'));
function ClaimDetailView(props: any) {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress/>}>
        <ClaimDetail {...props} />
      </Suspense>
    </MainView>
  );
}

export default ClaimDetailView;
