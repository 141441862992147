import React from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';


const GlobalNotificationsList = React.lazy(() => import('../components/GlobalNotificationsList'));

function GlobalNotificationsListView(props: any) {
  return (
    <MainView>
      <React.Suspense fallback={<CircularProgress/>}>
        <GlobalNotificationsList {...props} />
      </React.Suspense>
    </MainView>
  );
}

export default GlobalNotificationsListView;
