import {
  CollisionReportItem, CollisionReportSummary,
  DashboardItem,
  ReportAction, SET_COLLISION_REPORT_ITEM_LIST, SET_COLLISION_REPORT_SUMMARY,
  SET_WAYBILL_DASHBOARD_COUNT,
  SET_WAYBILL_DASHBOARD_LIST,
} from './types';

export interface DashboardState {
  waybillDashboardList: DashboardItem[],
  waybillDashboardCount: number,
  collisionReportItems: CollisionReportItem[],
  collisionReportSummary: CollisionReportSummary | null,
}

const initialState: DashboardState = {
  waybillDashboardList: [],
  waybillDashboardCount: 0,
  collisionReportItems: [],
  collisionReportSummary: null,
};

export default function (state = initialState, action: any | ReportAction): DashboardState {
  switch (action.type) {
    case SET_WAYBILL_DASHBOARD_LIST:
      return { ...state, waybillDashboardList: action.list };
    case SET_WAYBILL_DASHBOARD_COUNT:
      return { ...state, waybillDashboardCount: action.count };
    case SET_COLLISION_REPORT_ITEM_LIST:
      return { ...state, collisionReportItems: action.list };
    case SET_COLLISION_REPORT_SUMMARY:
      return { ...state, collisionReportSummary: action.summary };
    default:
      return state;
  }
}
