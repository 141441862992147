import React, {Suspense} from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';

const Dashboard = React.lazy(() => import('../components/Dashboard'));

function DashboardView() {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress />}>
        <Dashboard />
      </Suspense>
    </MainView>
  );
}

export default DashboardView;
