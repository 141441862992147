import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { loginAs, signOut } from '../apps/auth/action-creators';
import useDispatch from '../utils/hooks/useDispatch';

function LoginAsView({ location: { search } }: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { accessToken, refreshToken } = qs.parse(search);
    if (typeof accessToken === 'string' && typeof refreshToken === 'string') {
      const promise = dispatch(loginAs(accessToken, refreshToken)).then(
        () => {
          console.debug('replate');
          history.replace('/');
        },
        () => {
          dispatch(signOut());
        },
      );
      console.debug('promise', promise)
    }
  }, [search]);

  console.debug('search', search);

  return (
    <div />
  );
}

export default LoginAsView;
