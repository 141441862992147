export const SET_WAYBILL_LIST = 'SET_WAYBILL_LIST';
export const SET_WAYBILL_COUNT = 'SET_WAYBILL_COUNT';
export const SET_CURRENT_WAYBILL = 'SET_CURRENT_WAYBILL';
export const SET_CURRENT_WAYBILL_TRACKING = 'SET_CURRENT_WAYBILL_TRACKING';

export type WaybillContactPhone = {
  number: string
};

export type WaybillListItem = {
  id: number,
  plsNumber: string,
  serviceNumber: string,
  serviceOrderNumber: string,
  orderNumber: string,
  deliveryService: string,
  rate: string,
  minDeliveryDays: number,
  maxDeliveryDays: number,
  createdAt: string,
  state: string,
  stateData: string,
  senderCity: string,
  receiverCity: string,
  accountId: number,
  tagInsurance: boolean,
  tagOnlineShop: boolean,
  tagToTerminal: boolean,
};

export interface WaybillContactType {
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  companyName: string,
  contactName: string,
  longitude: number,
  latitude: number,
  phones: Array<WaybillContactPhone>,
  office: string,
  email: string,
  comment: string,
  addressLine?: string,
  contactId: string,

  intercomNumber?: string,
  intercomCode?: string,
  needPass?: boolean,
  needIdCard?: boolean,
  workingFrom?: string,
  workingTo?: string,
  lunchFrom?: string,
  lunchTo?: string,
  preCallPeriod?: number,
}

export type WaybillTerminal = {
  terminalId: number,
  code: string,
  title: string,
  iso: string,
  city: string,
  address: string,
  comment: string,
  longitude: number,
  latitude: number,
  deliveryService: string,
  geographyId: string,
  postalCode: string,
};

export type WaybillCargoPackage = {
  width: number,
  height: number,
  length: number,
  weight: number,
  description: string,
  amount: number,
};

export enum WaybillCargoAdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING',
  BY_HAND = 'BY_HAND'
}

export type WaybillCargoAdditionalService = {
  serviceCode: WaybillCargoAdditionalServiceCode;
};

export interface WaybillCargo {
  cargoId: string,
  packages: Array<WaybillCargoPackage>
  services: WaybillCargoAdditionalService[]
}

export type WaybillRate = {
  rateResultId: string,
  deliveryService: string,
  code: string,
  name: string,
  cost: number,
  days: number,
  price: number,
  minDays: number,
  maxDays: number,
};

export type WaybillClaimDetail = {
  claimNumber: string,
  claimId: number,
};

export interface WaybillPricing {
  code: string,
  amount: number,
  comment: string
}

export type WaybillOrder = {
  cost: number,
  orderNumber: string,
  status: string
};


export type Waybill = {
  id: number,
  plsNumber: string,
  serviceNumber: string,
  deliveryService: string,
  plsOrderNumber: string,
  serviceOrderNumber: string,
  plsStatus: string,
  createdAt: string,
  order?: WaybillOrder,
  record: {
    senderContact: WaybillContactType | null,
    receiverContact: WaybillContactType | null,
    senderTerminal: WaybillTerminal | null,
    receiverTerminal: WaybillTerminal | null,
    cargo: WaybillCargo | null,
    rate: WaybillRate | null,
    pickupDate: string,
    invoiceUrl: string,
    deliveryDate: string,
    forwardPlsWaybillNumber?: string,
    returnPlsWaybillNumber?: string,
    forwardWaybillServiceNumber?: string,
    returnWaybillServiceNumber?: string,
    forwardWaybillId?: string,
    returnWaybillId?: string,
    exportInvoiceUrl: string,
    pickupPeriod: string,
    newSenderContact: Partial<WaybillContactType> | null,
    newReceiverContact: Partial<WaybillContactType> | null,
  },
  attentionId?: number,
  stateData: string,
  state: string,
  claim?: WaybillClaimDetail,
  accountId: number,
  managerComment: string,
  creationKey: string | null,
};

export interface WaybillTracking {
  id: number,
  plsStatus: string,
  description: string,
  date: string,
}

export interface SetWaybillList {
  type: typeof SET_WAYBILL_LIST,
  list: WaybillListItem[],
}

export interface SetWaybillCount {
  type: typeof SET_WAYBILL_COUNT,
  count: number,
}

export interface SetCurrentWaybill {
  type: typeof SET_CURRENT_WAYBILL,
  waybill: Waybill | null,
}

export interface SetCurrentWaybillTracking {
  type: typeof SET_CURRENT_WAYBILL_TRACKING,
  list: WaybillTracking[],
}

export type waybillActions = SetWaybillList | SetWaybillCount | SetCurrentWaybill | SetCurrentWaybillTracking;
