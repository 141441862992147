import React, { Suspense } from 'react';
import MainView from '../../../views/MainView';
import CircularProgress from '../../../components/CircularProgress';

const WaybillList = React.lazy(() => import('../components/WaybillList'));
function WaybillListView(props: anyObject) {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress />}>
        <WaybillList {...props} />
      </Suspense>
    </MainView>
  );
}

export default WaybillListView;
