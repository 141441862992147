import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import NonAuthorizedRoute from './NonAuthorizedRoute';
import UserLoginView from '../apps/auth/views/UserLoginView';
import PrivateRoute from './PrivateRoute';
import WaybillListView from '../apps/waybill/views/WaybillListView';
import WaybillDetailView from '../apps/waybill/views/WaybillDetailView';
import ChatView from '../apps/chat/views/ChatView';
import SafetyOfCargoView from '../apps/dashboard/views/SafetyOfCargoView';
import LoginAsView from '../views/LoginAsView';
import CollisionReportView from '../apps/dashboard/views/CollisionReportView';
import ChatAnswerStatisticView from '../apps/statistic/views/ChatAnswerStatisticView';
import DashboardView from '../apps/dashboard/views/DashboardView';
import NoPermissionsView from '../apps/auth/views/NoPermissionsView';
import ClaimDetailView from '../apps/claim/views/ClaimDetailView';
import ClaimListView from '../apps/claim/views/ClaimListView';
import GlobalNotificationsListView from '../apps/notifications/views/GlobalNotificationsListView';

import GlobalNotificationDetailView from '../apps/notifications/views/GlobalNotificationDetailView';

function Router() {
  return (
    <Switch>
      <NonAuthorizedRoute component={UserLoginView} path="/login" exact />

      <PrivateRoute component={DashboardView} path="/" exact />
      <PrivateRoute component={WaybillListView} path="/waybills" exact />
      <PrivateRoute component={WaybillDetailView} path="/waybill/:id" exact />
      <PrivateRoute component={ChatView} path="/chat" exact />
      <PrivateRoute component={SafetyOfCargoView} path="/dashboard" exact />
      <PrivateRoute component={CollisionReportView} path="/collision_report" exact />
      <PrivateRoute component={ChatAnswerStatisticView} path="/statistic/chat" exact />

      <PrivateRoute exact path="/claim" component={ClaimListView} />
      <PrivateRoute exact path="/claim/:claimId" component={ClaimDetailView} />

      <PrivateRoute component={NoPermissionsView} path="/denied" exact deniedView />
      <PrivateRoute exact path="/notifications/global" component={GlobalNotificationsListView} />
      <PrivateRoute exact path="/notifications/global/create" component={GlobalNotificationDetailView} create />
      <PrivateRoute exact path="/notifications/global/:notificationId" component={GlobalNotificationDetailView} />

      <Route
        exact
        path="/login_as"
        component={LoginAsView}
      />

      <Redirect to="/" />
    </Switch>
  );
}

export default Router;
