import {
  ChatAnswerPieSeries,
  ChatAnswerSeries,
  SET_CHAT_ANSWER_PIE_STAT,
  SET_CHAT_ANSWER_STAT,
  StatisticActions
} from './types';

type StatisticState = {
  chatAnswerStat: ChatAnswerSeries[],
  chatAnswerPieStat: ChatAnswerPieSeries[],
};

const initialState: StatisticState = {
  chatAnswerStat: [],
  chatAnswerPieStat: []
};

export default function (state = initialState, action: StatisticActions): StatisticState {
  switch (action.type) {
    case SET_CHAT_ANSWER_STAT:
      return { ...state, chatAnswerStat: action.stat };
    case SET_CHAT_ANSWER_PIE_STAT:
      return { ...state, chatAnswerPieStat: action.stat };
    default: return state;
  }
}
