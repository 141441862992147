import React, { Suspense } from 'react';
import MainView from '../../../views/MainView';
import CircularProgress from '../../../components/CircularProgress';

const WaybillDetail = React.lazy(() => import('../components/WaybillDetail'));
function WaybillDetailView(props: any) {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress />}>
        <WaybillDetail {...props} />
      </Suspense>
    </MainView>
  );
}

export default WaybillDetailView;
