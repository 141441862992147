export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH';

export interface SetHeaderHeight {
  type: typeof SET_HEADER_HEIGHT,
  height: number,
}

export interface SetScreenWidth {
  type: typeof SET_SCREEN_WIDTH,
  width: number,
}

export type settingsActions = SetHeaderHeight | SetScreenWidth;
