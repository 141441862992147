import React, { Suspense } from 'react';
import MainView from '../../../views/MainView';
import CircularProgress from '../../../components/CircularProgress';

const Chat = React.lazy(() => import('../components/Chat'));
function ChatView() {
  return (
    <MainView wrapperClassName="gx-chat-content-wrapper">
      <Suspense fallback={<CircularProgress />}>
        <Chat />
      </Suspense>
    </MainView>
  );
}

export default ChatView;
