export const SET_CHAT_ANSWER_STAT = 'SET_CHAT_ANSWER_STAT';
export const SET_CHAT_ANSWER_PIE_STAT = 'SET_CHAT_ANSWER_PIE_STAT';

export type ChatAnswerSeries = {
  date: string;
  latency: number;
};

export type ChatAnswerPieSeries = {
  name: string;
  count: number;
  fill: string;
};

export interface SetChatAnswerStat {
  type: typeof SET_CHAT_ANSWER_STAT,
  stat: ChatAnswerSeries[],
}

export interface SetChatAnswerPieStat {
  type: typeof SET_CHAT_ANSWER_PIE_STAT,
  stat: ChatAnswerPieSeries[]
}

export type StatisticActions = SetChatAnswerStat | SetChatAnswerPieStat;
