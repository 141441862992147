import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';

const ChatAnswerStatistic = React.lazy(() => import('../components/ChatAnswerStatistic'));

function ChatAnswerStatisticView(props: any) {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress />}>
        <ChatAnswerStatistic {...props} />
      </Suspense>
    </MainView>
  );
}

export default ChatAnswerStatisticView;
