import { UploadFile } from 'antd/es/upload/interface';
import { Moment } from 'moment';
import { Claim } from '../claim/types';
import { Waybill } from '../waybill/types';

export const SET_DIALOG_LIST = 'SET_DIALOG_LIST';
export const SET_DIALOG_COUNT = 'SET_DIALOG_COUNT';
export const SET_CURRENT_DIALOG = 'SET_CURRENT_DIALOG';
export const SET_MESSAGES_LIST = 'SET_MESSAGES_LIST';
export const SET_MESSAGES_COUNT = 'SET_MESSAGES_COUNT';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_UPLOAD_FILE = 'ADD_UPLOAD_FILE';
export const RESET_UPLOAD_FILES = 'RESET_UPLOAD_FILES';
export const REMOVE_UPLOAD_FILE = 'REMOVE_UPLOAD_FILE';
export const REPLACE_MESSAGE = 'REPLACE_MESSAGE';
export const ADD_DIALOGS = 'ADD_DIALOGS';
export const REPLACE_DIALOG = 'REPLACE_DIALOG';
export const RESET_CURRENT_DIALOG = 'RESET_CURRENT_DIALOG';
export const SET_CHAT_TAB = 'SET_CHAT_TAB';
export const REMOVE_DIALOG_BY_ID = 'REMOVE_DIALOG_BY_ID';
export const SET_DIALOG_CHECKER_TIMER = 'SET_DIALOG_CHECKER_TIMER';
export const SET_WAYBILL_FOR_DIALOG = 'SET_WAYBILL_FOR_DIALOG';

type DialogWaybillInfo = {
  id: number,
  plsNumber: string,
  serviceNumber: string,
  plsOrderNumber: string,
  plsStatus: string,
  createdAt: string,
  serviceOrderNumber: string,
  state: string,
  stateData: string,
};

type BasicDialog = {
  accountId: number,
  createdAt: string,
  dialogId: string,
  lastMessage: string,
  closed: boolean,
  lastMessageDate: Moment,
  waybillNumber: string | null,
  claimNumber: string | null,
  postponedUntil?: string,
  postponedAt?: string,
};

type WaybillDialog = BasicDialog & {
  waybillNumber: string,
  waybill: DialogWaybillInfo,
};

type ClaimDialog = BasicDialog & {
  claimNumber: string,
  claim: Claim,
};

export type Dialog = WaybillDialog & ClaimDialog;

export interface DialogFile {
  name: string,
  fileName: string | undefined,
  url?: string,
  uploadPercent?: number,
  status?: 'error' | 'success' | 'done' | 'uploading' | 'removed';
}

export interface ShortUserInfo {
  firstName: string | null | undefined,
  lastName: string | null| undefined,
  avatar: string | null | undefined,
}

export interface Message {
  messageId: string,
  accountId?: number,
  userId: number,
  message?: string,
  file?: DialogFile,
  dialogId: string,
  createdAt: string,
  type: string,
  sender: string,
  user?: ShortUserInfo
}

export enum ChatTab {
  ACTIVE='active',
  POSTPONED='postponed'
}

export interface SetCurrentDialog {
  type: typeof SET_CURRENT_DIALOG,
  dialog: Dialog | null
}

export interface SetMessagesList {
  type: typeof SET_MESSAGES_LIST,
  messages: Message[]
}

export interface AddMessages {
  type: typeof ADD_MESSAGES,
  messages: Message[]
}

export interface AddMessage {
  type: typeof ADD_MESSAGE,
  message: Message
}

export interface AddUploadFile {
  type: typeof ADD_UPLOAD_FILE,
  file: UploadFile,
}

export interface ResetUploadFiles {
  type: typeof RESET_UPLOAD_FILES
}

export interface RemoveUploadFile {
  type: typeof REMOVE_UPLOAD_FILE,
  file: UploadFile
}

export interface ReplaceMessage {
  type: typeof REPLACE_MESSAGE,
  message: Message,
  messageId: string
}

export interface SetMessagesCount {
  type: typeof SET_MESSAGES_COUNT,
  count: number,
}

export interface SetDialogList {
  type: typeof SET_DIALOG_LIST,
  list: Dialog[],
}

export interface SetDialogCount {
  type: typeof SET_DIALOG_COUNT,
  count: number,
}

export interface AddDialogs {
  type: typeof ADD_DIALOGS,
  list: Dialog[]
}

export interface ReplaceDialog {
  type: typeof REPLACE_DIALOG,
  dialog: Dialog,
}

export interface ResetCurrentDialog {
  type: typeof RESET_CURRENT_DIALOG,
}

export interface SetChatTab {
  type: typeof SET_CHAT_TAB,
  tab: ChatTab,
}

export interface RemoveDialogById {
  type: typeof REMOVE_DIALOG_BY_ID,
  dialogId: string,
}

export interface SetDialogCheckerTimer {
  type: typeof SET_DIALOG_CHECKER_TIMER,
  timer: any,
}

export interface SetWaybillForDialog {
  type: typeof SET_WAYBILL_FOR_DIALOG,
  waybill: Waybill,
}

export type ChatActions = SetCurrentDialog | SetMessagesList | SetMessagesCount | AddMessage | AddMessages | AddUploadFile |
ResetUploadFiles | RemoveUploadFile | ReplaceMessage | SetDialogList | SetDialogCount | AddDialogs | ReplaceDialog |
ResetCurrentDialog | SetChatTab | RemoveDialogById | SetDialogCheckerTimer | SetWaybillForDialog;
