import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';
import MainView from '../../../views/MainView';

const ClaimList = React.lazy(() => import('../components/ClaimList'));

function ClaimListView(params: anyObject) {
  return (
    <MainView>
      <Suspense fallback={<CircularProgress/>}>
        <ClaimList {...params} />
      </Suspense>
    </MainView>
  );
}

export default ClaimListView;
