type anyObject = {
  [key: string]: any;
}

export default function replaceByFieldValue<T extends anyObject>(arr: Array<T>, key: string, newObject: T): Array<T> {
  const tmp = arr.slice();
  const index = tmp.findIndex((_) => _[key] === newObject[key]);
  if (index > -1) tmp[index] = newObject;
  return tmp;
}

