import {
  SET_CURRENT_WAYBILL,
  SET_CURRENT_WAYBILL_TRACKING,
  SET_WAYBILL_COUNT,
  SET_WAYBILL_LIST,
  Waybill,
  waybillActions,
  WaybillListItem,
  WaybillTracking,
} from './types';

type WaybillState = {
  waybillList: WaybillListItem[],
  waybillCount: number,
  currentWaybill: Waybill | null,
  currentWaybillTracking: WaybillTracking[]
};

const initialState: WaybillState = {
  waybillList: [],
  waybillCount: 0,
  currentWaybill: null,
  currentWaybillTracking: [],
};

export default function (state = initialState, action: waybillActions) {
  switch (action.type) {
    case SET_WAYBILL_LIST:
      return { ...state, waybillList: action.list };
    case SET_WAYBILL_COUNT:
      return { ...state, waybillCount: action.count };
    case SET_CURRENT_WAYBILL:
      return { ...state, currentWaybill: action.waybill };
    case SET_CURRENT_WAYBILL_TRACKING:
      return { ...state, currentWaybillTracking: action.list };
    default: return state;
  }
}
