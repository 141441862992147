import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import Badge from 'antd/es/badge';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import useSelector from '../../utils/hooks/useSelector';
import { getMenuItems } from './utils';

type MenuPropsChild = {
  children?: any,
  title?: string,
  to?: string,
  counter?: number,
  component?: React.ReactNode
};

interface MenuProps {
  children?: MenuPropsChild[],
  title?: string,
  to?: string,
  counter?: number,
  component?: React.ReactNode
}

const find = (item: MenuProps, path: string): boolean => {
  if (item.to === path) return true;
  if (item.children) return item.children.some((_) => find(_, path));
  return false;
};

const renderMenu = (menuItem: MenuProps, active = false) => {
  if (menuItem.component) return menuItem.component;

  const wrap = (el: React.ReactNode) => {
    if (typeof menuItem.counter === 'number') {
      return (
        <Badge count={menuItem.counter} overflowCount={999}>
          {el}
        </Badge>
      );
    }
    return el;
  };

  // if (typeof menuItem.counter === 'number') {
  //   return (
  //     <Menu.Item
  //       className={classNames('ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal', { 'ant-menu-item-active': active })}
  //     >
  //       <Link
  //         className="ant-menu-submenu-title"
  //         to={menuItem.to || '/'}
  //       >
  //         <Badge count={menuItem.counter} overflowCount={999}>
  //           {menuItem.title}
  //         </Badge>
  //       </Link>
  //     </Menu.Item>
  //   );
  // }

  if (menuItem.children && menuItem.children.length) {
    return (
      <Menu.SubMenu
        title={wrap(menuItem.title)}
        className={classNames('ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal gx-border-0', { 'ant-menu-item-active': active })}
      >
        {menuItem.children.map((item: MenuProps) => (
          <Menu.Item key={item.to}>
            <Link className="ant-menu-submenu-title" to={item.to || '/'}>
              {item.title}
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    );
  }

  return (
    <Menu.Item className={classNames('ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal', { 'ant-menu-item-active': active })}>
      <Link
        className="ant-menu-submenu-title"
        to={menuItem.to || '/'}
      >
        {wrap(menuItem.title)}
      </Link>
    </Menu.Item>
  );
};

function HorizontalNav() {
  const { location: { pathname } } = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { globalCounters } = useSelector((state) => state.counters);

  const menu: Array<MenuProps> = React.useMemo(() => getMenuItems(globalCounters), [globalCounters]);

  if (currentUser?.allowedService === '*') {
    menu.push({
      title: 'Контрольный статус',
      to: '/dashboard?deliveryService=cse',
    });
  }

  const current = React.useMemo(() => {
    const tmp = menu.find((_) => find(_, pathname));
    if (tmp) return menu.indexOf(tmp);
    return -1;
  }, [pathname]);

  return (
    <Menu
      className="ant-menu ant-menu-light ant-menu-root ant-menu-horizontal"
      mode="horizontal"
    >
      {menu.map((item, index) => renderMenu(item, current === index))}
    </Menu>
  );
}

export default HorizontalNav;
