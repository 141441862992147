import React from 'react';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import antRu from 'antd/es/locale/ru_RU';
import moment from 'moment';
import { history } from './reducers';
import Base from './containers/Base';
import 'moment/locale/ru';

moment.locale('ru');

function App({ store }: anyObject) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ConfigProvider locale={antRu}>
          <Base />
        </ConfigProvider>

      </ConnectedRouter>
    </Provider>
  );
}

export default App;
