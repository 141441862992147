import React from 'react';
import { Layout } from 'antd';
import TopBar from '../components/TopBar';

interface MainViewProps {
  children: React.ReactNode,
  wrapperClassName?: string,
}

function MainView({ children, wrapperClassName }: MainViewProps) {

  return (
    <Layout className="ant-layout">
      <TopBar />
      <div className="gx-layout-content gx-container-wrap  ant-layout-content">
        <div className={wrapperClassName || 'gx-main-content-wrapper'}>
          { children }
        </div>
      </div>
    </Layout>
  );
}

export default MainView;
