import {SET_HEADER_HEIGHT, SET_SCREEN_WIDTH, SetHeaderHeight, SetScreenWidth} from "./types";

export const setHeaderHeight = (height: number): SetHeaderHeight => ({
  type: SET_HEADER_HEIGHT,
  height,
});

export const setScreenWidth = (width: number): SetScreenWidth => ({
  type: SET_SCREEN_WIDTH,
  width,
});

