export const SET_DELIVERY_SERVICES = 'SET_DELIVERY_SERVICES';
export const SET_SELLER_LIST = 'SET_SELLER_LIST';
export const SET_SURCHARGE_TYPES_LIST = 'SET_SURCHARGE_TYPES_LIST';
export const SET_RATE_LIST = 'SET_RATE_LIST';

export interface DeliveryService {
  key: string,
  title: string,
  icon: string,
  rating: number,
}

export type Seller = {
  sellerId: number,
  title: string,
  nds: boolean
};

export type SurchargeType = {
  id: number,
  title: string,
  code: string,
};

export interface SetDeliveryServices {
  type: typeof SET_DELIVERY_SERVICES,
  deliveryServices: Array<DeliveryService>
}

export type RateItem = {
  code: string,
  deliveryService: string,
  title: string,
  enabled: boolean,
  rateId: number,
  groupId: number,
  enableForPublic: boolean,
  enableForCarrier: boolean,
  enableForShop: boolean,
};


export interface SetSellerList {
  type: typeof SET_SELLER_LIST,
  list: Seller[],
}

export interface SetSurchargeTypesList {
  type: typeof SET_SURCHARGE_TYPES_LIST,
  list: SurchargeType[],
}

export interface SetRateList {
  type: typeof SET_RATE_LIST,
  rates: RateItem[],
}

export type ReferenceActions = SetDeliveryServices | SetSellerList | SetSurchargeTypesList | SetRateList;
