import {
  applyMiddleware, compose, createStore,
} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import JWTMiddleware from './utils/middlewares';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState: object, httpService: any) {
  const store = createStore(rootReducer, initialState,
    composeEnhancers(applyMiddleware(JWTMiddleware(), thunk.withExtraArgument(httpService))));

  // @ts-ignore
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // @ts-ignore
    module.hot.accept('./reducers', () => {
      // const nextRootReducer = require('./reducers');
      store.replaceReducer(rootReducer);
    });
  }
  return store;
}
