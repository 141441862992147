import React from 'react';
import { GlobalCounters } from '../../apps/counter/types';

type MenuPropsChild = {
  children?: any,
  title?: string,
  to?: string,
  counter?: number,
  component?: React.ReactNode
};

interface MenuProps {
  children?: MenuPropsChild[],
  title?: string,
  to?: string,
  counter?: number,
  component?: React.ReactNode
}

const getMenuItems = (counters: GlobalCounters): Array<MenuProps> => [
  {
    title: 'Дашборд',
    to: '/',
  },
  {
    title: 'Заказы',
    to: '/waybills',
  },
  {
    title: 'Заявки',
    to: '/claim?status=new',
  },
  {
    title: 'Обращения',
    to: '/chat',
    counter: counters.activeDialogs,
    children: [
      {
        title: 'Обращения',
        to: '/chat',
        counter: counters.activeDialogs,
      },
      {
        title: 'Уведомление клиентов',
        to: '/notifications/global',
      },
    ],
  },
  {
    title: 'Контрольный статус',
    to: '/dashboard',
  },
  {
    title: 'Отчеты',
    children: [
      {
        title: 'Отчет по контрольным статусам',
        to: '/collision_report',
      },
    ],
  },
];

export { getMenuItems };
