import React from 'react';
import loader from '../assets/images/loading.svg';

export interface CircularProgressProps {
  className?: string,
}

const CircularProgress = ({ className }: CircularProgressProps) => (
  <div className={`loader ${className}`}>
    <img src={loader} alt="loader" />
  </div>
);
export default CircularProgress;
