import React, { JSXElementConstructor } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useSelector from '../utils/hooks/useSelector';

export interface PrivateRouteProps extends RouteProps {
  component: JSXElementConstructor<any>,
  deniedView?: boolean,
  [key: string]: any
}

const getNext = (pathname: string, search: string) => escape(`${pathname}${search}`);

function PrivateRoute({
  component: Component, deniedView, ...rest
}: PrivateRouteProps) {
  const user = useSelector((state) => state.auth.currentUser);
  if (!user) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={`/login?next=${getNext(props.location.pathname, props.location.search)}`}
            from={props.location.pathname}
          />
        )}
      />
    );
  }

  if (!deniedView && !user.allowedService) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to="denied"
            from={props.location.pathname}
          />
        )}
      />
    );
  }

  if (deniedView && !!user.allowedService) {
    return (
      <Route
        {...rest}
        render={(props) => <Redirect to="/" />}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
}

export default PrivateRoute;
