import {SET_HEADER_HEIGHT, SET_SCREEN_WIDTH, settingsActions} from './types';

type settingsState = {
  headerHeight: number,
  screenWidth: number,
}

const initialState: settingsState = {
  headerHeight: 0,
  screenWidth: window.innerWidth,
};

export default function (state = initialState, action: settingsActions): settingsState {
  switch (action.type) {
    case SET_HEADER_HEIGHT:
      return { ...state, headerHeight: action.height };
    case SET_SCREEN_WIDTH:
      return { ...state, screenWidth: action.width };
    default: return state;
  }
}
