import Centrifuge from 'centrifuge';
import { adaptFromApi } from './adapter';
import { ThunkAction } from '../types/redux';
import { setCent, setToken, signOut } from '../apps/auth/action-creators';
import { WSDialogEvent } from '../apps/chat/action-creators';
import { loadGlobalCounters } from '../apps/counter/action-creators';
import getAppConfig from './getAppConfig';

export default function subscribe(): ThunkAction<void> {
  return (dispatch, getState, http) => {
    const { auth: { currentUser, cent, token } } = getState();
    if (!currentUser || !token) return;
    const { allowedService } = currentUser;
    if (cent) cent.disconnect();
    const centrifugeHost = getAppConfig().centrifugeHost;
    const c = new Centrifuge(`wss://${centrifugeHost}/connection/websocket`, {
      // debug: true,
      onRefresh: (ctx, cb) => {
        http.post('/api/v1/user/token/update/', { refresh_token: token.refreshToken }, { NO_AUTH: true }).then(
          (response: any) => {
            const { accessToken, refreshToken } = response;
            const expiredAt = new Date().getTime() + 300000;
            dispatch(setToken(accessToken, refreshToken, expiredAt));
            cb({ status: 200, data: { token: accessToken } });
          },
          () => {
            dispatch(signOut());
            c.disconnect();
          },
        );
      },
      onPrivateSubscribe: (ctx, cb) => {
        http.post('/api/v1/user/cent/subscribe/', ctx.data).then(
          (response: any) => {
            cb({ status: 200, data: response });
          },
        );
      },
    });
    if (token.accessToken) c.setToken(token.accessToken);
    c.subscribe(`$partners:${allowedService}`, (message) => {
      const { data: { event, ...data } } = adaptFromApi(message);
      console.debug('data', message, event, data);
      dispatch(WSDialogEvent(data, event));
      dispatch(loadGlobalCounters());
    });
    c.connect();
    dispatch(setCent(c));
  };
}
