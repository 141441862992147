import React, { useCallback } from 'react';
import { Badge, Button, Card, Divider, Result } from 'antd';
import useSelector from '../../../utils/hooks/useSelector';
import useDispatch from '../../../utils/hooks/useDispatch';
import { signOut, switchAccount } from '../action-creators';

function NoPermissionsView() {
  const dispatch = useDispatch();
  const { currentUser, userAccountList } = useSelector((state) => state.auth);

  const handleSignOut = useCallback(() => dispatch(signOut()), []);

  const handleSwitch = useCallback(
    (accountId) => {
      dispatch(switchAccount(accountId));
    },
    [dispatch],
  );

  return (
    <div className="gx-d-flex gx-align-items-center gx-justify-content-center gx-w-100 gx-h-100">
      <Card className="gx-card">
        <Result
          status="warning"
          title="У вас нет доступа к этому сервису"
          extra={
            <>
              {userAccountList.length > 1 && (
                <div className="gx-mt-2">
                  <Divider/>
                  {userAccountList.map((account) => (
                    account.accountId === currentUser?.accountId
                      ? (
                        <div>
                          <Badge status="warning" className="gx-m-0"/>
                          {account.title}
                          <Divider/>
                        </div>
                      )
                      : (
                        <div className="gx-pointer" onClick={() => handleSwitch(account.accountId)}>
                          {account.title}
                          <Divider/>
                        </div>
                      )
                  ))}

                </div>
              )}

              <div>
                <Button onClick={handleSignOut}>
                  Выйти
                </Button>
              </div>
            </>
          }
        />
      </Card>

    </div>
  );
}

export default NoPermissionsView;
