import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const UserLogin = React.lazy(() => import('../components/UserLogin'));

function UserLoginView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <UserLogin />
    </Suspense>
  );
}

export default UserLoginView;
